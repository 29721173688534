import axios from 'axios';
import { Key, LockKey, LockKeyOpen, Phone } from 'phosphor-react';
import React, { useEffect, useRef, useState } from 'react';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { RiErrorWarningLine } from 'react-icons/ri';
import { useHistory } from 'react-router-dom';
import verify from "../../assets/img/Forget.svg";
import tic from "../../assets/img/tic.svg";
import { showToast } from '../../utils/ToastHelper';
import { BASE_URL } from '../Const/Url';

const ForgetOtp = () => {
    const history = useHistory();
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [activeOtp, setActiveOtp] = useState(0);
  const [finalOtp, setFinalOtp] = useState(null);
  const [eye, setEye] = useState(false);
  const [eye2, setEye2] = useState(false);
  const [numberValidation, setNumberValidation] = useState("+880");
  const [passValidation, setPassValidation] = useState("");
  const [finalPass, setFinalPass] = useState("");
  const changeEye = () => {
    setEye((prev) => !prev);
  };
  const changeEye2 = () => {
    setEye2((prev) => !prev);
  };
  const inputRef = useRef();
  const handleOtpChange = ({ target }, index) => {
    const { value } = target;
    const newOtp = [...otp];
    newOtp[index] = value.substring(value.length - 1);
    // const val = value.substring(value.length - 1)
  
    if (!value) setActiveOtp(index - 1);
    else setActiveOtp(index + 1);
    setOtp(newOtp);
    setFinalOtp(newOtp.join());
  };
  
  const handleKeyDown = ({ key }, index) => {
    if (key === "Backspace") setActiveOtp(index - 1);
  
  };
  useEffect(() => {
    inputRef.current?.focus();
  }, [activeOtp]);
  const mobile = localStorage.getItem("userNumberOtp");
  const SignInData = (pass) => {
    const data = new FormData();
    data.append("mobile", mobile);
    data.append("password", pass);
    const url = `${BASE_URL}api/v1/auth/login/`;
    
      axios
      .post(url, data)
      .then((res) => {
        if (res.data.status) {
          
          showToast("success", "Logged In Successfully.");
          // Store data's to local storage
          localStorage.setItem("is_logged_in", true);
          localStorage.setItem("access_token", res.data.data.token);
          localStorage.setItem("userData", JSON.stringify(res.data.data));
          if (localStorage.getItem("is_logged_in")) {
            history.push("/");
          }
        }
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        const errorMsg = JSON.parse(err.request.response).errors;
        for (let value of Object.values(errorMsg)) {
          showToast("error", value[0]);
        }
        
        showToast("error", message);
      });
    
  };
  const submitOtp = () => {
    const otpC = `${otp[0]}${otp[1]}${otp[2]}${otp[3]}${otp[4]}${otp[5]}`
    localStorage.setItem('forgetOtp',otpC)
    if (finalOtp?.length !== 6) {
      showToast("error", "Otp Should Be 6 Digit");
    }else if(finalPass.length < 8){
      showToast("error", "Enter minimum 8 digit password!");
    }else {
      const data = {
        // code: `${otp[0]}${otp[1]}${otp[2]}${otp[3]}${otp[4]}${otp[5]}`,
        code: finalOtp,
        mobile: mobile,
        password:finalPass
      };
      const url = `${BASE_URL}api/v1/auth/forget/password/confirm/`;
      console.log(data,'final data')
      axios
        .post(url, data)
        .then((res) => {
          if (res.data.status) {
            SignInData(finalPass)
          }
        })
        .catch((err) => {
          const message = JSON.parse(err.request.response).message;
          const errorMsg = JSON.parse(err.request.response).errors;
          for (let value of Object.values(errorMsg)) {
            showToast("error", value[0]);
            console.log(value[0],'otp error')
          }
          
          showToast("error", message);
          
        });
    }
  };
    return (
        <div className="login_main">
      <div>
        <div className="logo_image" style={{ position: "relative" }}>
          <img className="img-fluid" src={verify} alt="" />
          <img className="img-fluid tic" src={tic} alt="" />
        </div>
        <h3 className="login_text">Verify</h3>
        <span className="verify-text">
          Please enter your 6 digit code and password
        </span>
        <div className="input_container create mt-3">
            <div className="number">
              <div className="icons-div">
                <Key size={24} color="white" weight="light" />
              </div>
              <input
                type="number"
                // onChange={(e) => setNumberValidation(e.target.value)}
                placeholder="Type OTP"
                name=""
                id=""
                autocomplete="off"
                value={finalOtp}
                onChange={(e) => setFinalOtp(e.target.value)}
              />
            </div>
            {finalOtp?.length < 6 ||
              finalOtp?.length > 6 ? (
                <span>
                  <RiErrorWarningLine /> Must Be 6 characters
                </span>
              ) :finalOtp?.length === 0 ? <></> : <></>}
            {/* {isNaN(Number(numberValidation)) == true &&
              numberValidation.length > 0 && (
                <span className="mt-1">
                  <RiErrorWarningLine /> Inter Number Only
                </span>
              )} */}
          </div>
          <div className="input_container create mt-3">
            <div className="number">
              <div className="icons-div">
                <LockKey size={24} color="white" weight="light" />
              </div>
              <input
                type={eye ? "text" : "password"}
                onChange={(e) => setPassValidation(e.target.value)}
                placeholder="Password"
                name=""
                id=""
                autocomplete="off"
              />
              <div onClick={() => changeEye()}>
                {eye ? (
                  <AiOutlineEye
                    style={{ cursor: "pointer" }}
                    size={24}
                    color="#0000001A"
                  />
                ) : (
                  <AiOutlineEyeInvisible
                    style={{ cursor: "pointer" }}
                    size={24}
                    color="#0000001A"
                  />
                )}
              </div>
            </div>
            {passValidation?.length > 0 && passValidation?.length < 8 && (
              <span>
                <RiErrorWarningLine /> Minimum 8 characters
              </span>
            )}
          </div>

          <div className="input_container create mt-3">
            <div className="number">
              <div className="icons-div">
                <LockKeyOpen size={24} color="white" weight="light" />
              </div>
              <input
                autocomplete="off"
                type={eye2 ? "text" : "password"}
                placeholder="Confirm Password"
                onChange={(e) => setFinalPass(e.target.value)}
                name=""
                id=""
              />
              <div onClick={() => changeEye2()}>
                {eye2 ? (
                  <AiOutlineEye
                    style={{ cursor: "pointer" }}
                    size={24}
                    color="#0000001A"
                  />
                ) : (
                  <AiOutlineEyeInvisible
                    style={{ cursor: "pointer" }}
                    size={24}
                    color="#0000001A"
                  />
                )}
              </div>
            </div>
            {finalPass?.length > 0 && finalPass !== passValidation && (
              <span>
                <RiErrorWarningLine />
                Password Didn't Match !
              </span>
            )}
          </div>
        
        <div className="input_container" style={{ marginTop: "37px" }}>
          <button
            className="w-100  btn-outline-none"
            onClick={() => submitOtp()}
          >
            Verify
          </button>
        </div>
      </div>
    </div>
    );
};

export default ForgetOtp;