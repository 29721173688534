import React from "react";
import "./Authentication.css";
import logo from "../../assets/logo/snowtexlogo.svg";
import BeatLoader from "react-spinners/BeatLoader";

const MainLoader = () => {
  return (
    <div className="loader_main main_bg">
      <div className="d-flex flex-column justify-content-center align-items-center">
        <img src={logo} alt="" />
        <div className="mt-4 ">
          <BeatLoader color="#fff" />
        </div>
      </div>
    </div>
  );
};

export default MainLoader;
