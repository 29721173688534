import {
  Avatar,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import React, { useRef, useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import avaterImg from "../../../assets/logo/avater.svg";
import img2 from "../../../assets/logo/image.svg";
import "./Chatroom.css";
import {
  Camera,
  Check,
  Checks,
  ClockCounterClockwise,
  DesktopTower,
  DotsThreeOutlineVertical,
  Files,
  ImageSquare,
  PaperPlaneTilt,
  Trash,
  User,
} from "phosphor-react";
import CancelIcon from "@mui/icons-material/Cancel";
import Webcam from "react-webcam";

import EmojiEmotionsOutlinedIcon from "@mui/icons-material/EmojiEmotionsOutlined";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";

import word from "../../../assets/logo/word 1.svg";
import excl from "../../../assets/logo/excel 1.svg";
import vedio from "../../../assets/logo/vedio.svg";
import music from "../../../assets/logo/music_note.svg";
import reply from "../../../assets/logo/reply.svg";
import docImg from "../../../assets/img/doc.png";

import { BASE_URL } from "../../Const/Url";
import axios from "axios";
import { useEffect } from "react";
import NoChat from "./NoChat";
import io from "socket.io-client";
import { showToast } from "../../../utils/ToastHelper";
import { useHistory } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import EmojiPicker from "emoji-picker-react";
import { Modal } from "react-bootstrap";
import { saveAs } from "file-saver";
import { useWebSocket } from "react-use-websocket/dist/lib/use-websocket";
import swal from "sweetalert";
const Chats = ({
  activeFnd,
  setRender,
  socket,
  index,
  setIndex,
  setActiveFnd,
  chatList,setLastStatus,lastStatus
}) => {
  const buttonRef = useRef(null);
  const [cam, setCam] = useState(false);
  const [show, setShow] = useState(false);
  const [show4, setShow4] = useState(false);
  const [showForword, setShowForword] = useState(false);
  const [show3, setShow3] = useState(false);
  const [currImg, setCurrImg] = useState(null);


  const handleClose = () => {
    setShow(false);
    setCurrImg(null);
    setCam(false);
  };
  const handleShow = () => setShow(true);


  const handleCloseForword = () => setShowForword(false);
  const handleClose4 = () => {
    setShow4(false);
    // setCurrImg(null);
  };
  const handleShow4 = () => setShow4(true);


  const handleClose3 = () => {
    setShow3(false);
    // setCurrImg(null);
  };


  const handleShow3 = () => setShow3(true);


  const handleDownload = () => {
    saveAs(currImg, "image.jpg");
  };


  var token = localStorage.getItem("access_token");
  localStorage.setItem("activeFnd", activeFnd.id);
  const cUser = JSON.parse(localStorage.getItem("userData"));
  const previousItem = localStorage.getItem("previousItem");
  const [newData, setNewData] = useState([]);


  const history = useHistory();
  const containerRef = useRef(null);
  const [messages, setMessages] = useState([]);
  const [open, setOpen] = useState([]);
  const [liveChat, setLiveChat] = useState([]);
  const [inputStr, setInputStr] = useState("");
  const [sendExcl, setSendExcl] = useState(null);
  const [sendWord, setSendWord] = useState(null);
  const [sendVedio, setSendVedio] = useState(null);
  const [sendAudio, setSendAudio] = useState(null);
  const [sendFile, setSendFile] = useState(null);
  const [showPicker, setShowPicker] = useState(false);
  const [attach, setAttach] = useState(false);
  const [chatCount, setChatCount] = useState(0);
  const [forwardData, setForwardData] = useState({});
  console.log(activeFnd.id, "activeFnd");
  console.log(chatCount, "chatCount");
  const [dataStatus, setdataStatus] = useState([]);
  console.log(sendFile, "img");


  useEffect(() => {
    function handleClickOutside(event) {
      if (buttonRef.current && !buttonRef.current.contains(event.target)) {
        setAttach(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [buttonRef]);
  const onEmojiClick = (emojiData, event) => {
    setInputStr(
      (prevInput) => prevInput + String.fromCodePoint(`0x${emojiData.unified}`)
    );
    console.log(emojiData);
    setShowPicker(false);
  };
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open2 = Boolean(anchorEl2);
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };
  const [anchorElFor, setAnchorElFor] = React.useState(null);
  const openFor = Boolean(anchorElFor);
  const handleClickFor = (event, data) => {
    setAnchorElFor(event.currentTarget);
    setForwardData(data);
  };
  const handleCloseFor = () => {
    setAnchorElFor(null);
  };


  const [ofset, setOfset] = useState(0);
  const [userData, setUserData] = useState({});


  useEffect(() => {
    setOfset(0);
  }, [activeFnd.id]);
  const userName = userData?.first_name + " " + userData?.last_name;
  // const lastSender = liveChat[liveChat?.length - 1]?.sender?.first_name;
  // console.log("lastSenderFirst", lastSender);


  // console.log(`user:${userName} -- lastSender:${lastSender}`);
  const userFirstName = userData?.first_name;


  // useEffect(() => {
  //   if (userFirstName !== lastSender && lastSender !== undefined) {
  //     socket.onmessage({
  //       event: "message_open",
  //       data: {
  //         conversation: chat?.id,
  //         message: liveChat[liveChat?.length - 1]?.id,
  //       },
  //     });
  //     setIndex(index + 1);
  //     getLiveChat();
  //     console.log(`Send By  ${lastSender}`)
  //   }
  // }, [messages,lastSender]);


  const [isLoading, setIsLoading] = useState(true);
  const [ndLoading, setndLoading] = useState(true);
  const [chat, setChat] = useState({});
  const getSingleChat = () => {
    const url = `${BASE_URL}api/v1/chat/mobile/conversation/${activeFnd.id}/`;
    axios
      .get(url)
      .then((res) => {
        // console.log("sender", res.data.data);
        setChat(res.data.data);
        // setLastStatus(lastStatus?.map(last => {
        //   if(last.id === activeFnd.id){
        //     return {...last,status: res.data?.data?.receiver?.is_online}
        //   }
        //   return last
        // }))
        setIsLoading(false);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        console.log(message);
      });
  };


  console.log("sender", chat);
  const activeStat = lastStatus?.map(stat => stat.id === activeFnd.id)
  useEffect(() => {
    const url = `${BASE_URL}api/v1/auth/profile/`;
    axios
      .get(url)
      .then((res) => {
        console.log(res);
        setUserData(res.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        console.log(message);
      });
  }, []);
  // useEffect(()=>{
  //   const url = `${BASE_URL}api/v1/chat/mobile/conversation/${activeFnd.id}/`;
  //   axios
  //     .get(url)
  //     .then((res) => {
  //       setLastStatus(lastStatus?.map(last => {
  //         if(last.id === activeFnd.id){
  //           return {...last,status: res.data?.data?.receiver?.is_online}
  //         }
  //         return last
  //       }))
  //       const status = lastStatus?.map(last => {
  //         if(last.id === activeFnd.id){
  //           return {...last,status: res.data?.data?.receiver?.is_online}
  //         }
  //         return last
  //       }) 
  //       // console.log(status , 'event101')
  //       localStorage.setItem('lastStatLocal',status)
  //     })
  //     .catch((err) => {
  //       const message = JSON.parse(err.request.response).message;
  //       console.log(message);
  //     });
  // },[])


  console.log("userData", userData);
  // useEffect(()=>{
    
  // },[index])


  const getLiveChat = () => {
    const url = `${BASE_URL}api/v1/chat/mobile/conversation/${activeFnd.id}/messages/?limit=30&offset=${ofset}`;
    axios
      .get(url)
      .then((res) => {
        const data = res?.data?.data?.results;
        const newData = data.reverse();
        let lastIdOneIndex = newData.length - 1;
        for (let i = newData.length - 1; i >= 0; i--) {
          if (newData[i].sender.id !== cUser.id) {
            lastIdOneIndex = i;
            break;
          }
        }
        let changedArray = newData.slice(0, lastIdOneIndex + 1);
        const changeStatus = changedArray.map((obj) => {
          return {
            ...obj,
            status: 2,
          };
        });
        let unchangedArray = newData.slice(lastIdOneIndex + 1);
        setLiveChat([...changeStatus, ...unchangedArray]);
        console.log("newData", newData);
        setChatCount(res.data.data.count);
        console.log("liveChat2", liveChat);
        if (newData[newData?.length - 1]?.sender?.id !== cUser?.id) {
          socket.onmessage({
            event: "message_open",
            data: {
              conversation: activeFnd.id,
              message: newData[newData?.length - 1]?.id,
            },
          });
        }
        setndLoading(false);
      })
      // //
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        console.log(message);
      });
  };


  const getLiveChat2 = () => {
    const url = `${BASE_URL}api/v1/chat/mobile/conversation/${activeFnd.id}/messages/?limit=30&offset=${ofset}`;
    axios
      .get(url)
      .then((res) => {
        // console.log('Live_chat2',res.data.data.results);
        const data = res?.data?.data?.results;
        const newData = data;


        // // setLiveChat([...liveChat, ...newData]);
        // // const updated = [...liveChat,...newData]
        setNewData(newData.reverse());
        console.log(res.data.data.count);
        setChatCount(res.data.data.count);
        console.log("newData", newData);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        console.log(message);
      });
  };


  useEffect(() => {
    if (ofset === 0) {
      // setLiveChat([]);
      getLiveChat();
    } else {
      getLiveChat2();
      setLiveChat([...newData, ...liveChat]);
      setNewData([]);
    }
  }, [messages, activeFnd.id, activeFnd, ofset, index]);
  // index dependency
  function handleScroll(event) {
    // Check if the user has scrolled to the top of the chat
    if (liveChat?.length === chatCount) {
      return;
    }
    if (liveChat?.length === chatCount) {
      event.target.removeEventListener("scroll", handleScroll);
    } else if (liveChat?.length === chatCount && event.target.scrollTop === 0) {
      event.target.removeEventListener("scroll", handleScroll);
    } else if (liveChat?.length <= chatCount) {
      if (event.target.scrollTop === 0) {
        setOfset(ofset + 30);
      }
    }
  }


  //
  const videoConstraints = {
    width: 400,
    facingMode: "environment",
  };


  const webcamRef = useRef(null);
  const [url, setUrl] = React.useState(null);
  const capturePhoto = React.useCallback(async () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setUrl(imageSrc);


    // setSendFile(imageSrc)
    const decodedData = atob(imageSrc.split(",")[1]);


    // Create Blob object from decoded data
    const blob = new Blob([decodedData], { type: "image/jpg" });


    // Create File object from Blob object
    const file = new File([blob], "captured.jpg", { type: "image/jpg" });
    // console.log(file,'img blob');
    // console.log(file, "img 311");
    setSendFile(file);
    handleClose3();
  }, [webcamRef]);
  const onUserMedia = (e) => {
    console.log(e);
  };
  // console.log("imgsrc", url);
  useEffect(() => {
    getSingleChat();
  }, [activeFnd]);


  useEffect(() => {
    containerRef?.current?.scrollIntoView();
  }, [messages, getLiveChat]);


  const handleClick = (str, id) => {
    const url1 = `${BASE_URL}api/v1/auth/documents/upload/`;
    if (
      inputStr !== "" ||
      sendFile?.length > 0 ||
      sendVedio?.length > 0 ||
      sendAudio?.length > 0 ||
      sendWord?.length > 0 ||
      sendExcl?.length > 0
    ) {
      const data1 = new FormData();
      if (sendFile?.length > 0) {
        Object.values(sendFile).map((file) => {
          data1.append("document", file);
          data1.append("doc_type", 0);
          axios
            .post(url1, data1)
            .then((res) => {
              if (res.data.status) {
                console.log(res.data.data.id, "res.data.data.id");
                socket.send(
                  JSON.stringify({
                    event: "message_send",
                    data: {
                      conversation: id,
                      message: "",
                      attachments: [res.data.data.id],
                      sticker: null,
                      message_type: 0,
                    },
                  })
                );
              }
            })
            .catch((err) => {
              const message = JSON.parse(err.request.response).message;
              const errorMsg = JSON.parse(err.request.response).errors;
              for (let value of Object.values(errorMsg)) {
                showToast("error", value[0]);
              }
              console.log(err.request.response);
              showToast("error", message);
            });
        });
      } else if (sendVedio?.length > 0) {
        Object.values(sendVedio)?.map((file) => {
          data1.append("document", file);
          data1.append("doc_type", 1);
          axios
            .post(url1, data1)
            .then((res) => {
              if (res.data.status) {
                socket.send(
                  JSON.stringify({
                    event: "message_send",
                    data: {
                      conversation: id,
                      message: "",
                      attachments: [res.data.data.id],
                      sticker: null,
                      message_type: 0,
                    },
                  })
                );
              }
            })
            .catch((err) => {
              const message = JSON.parse(err.request.response).message;
              const errorMsg = JSON.parse(err.request.response).errors;
              for (let value of Object.values(errorMsg)) {
                showToast("error", value[0]);
              }
              console.log(err.request.response);
              showToast("error", message);
            });
        });
      } else if (sendAudio?.length > 0) {
        Object.values(sendAudio)?.map((file) => {
          data1.append("document", file);
          data1.append("doc_type", 2);
          axios
            .post(url1, data1)
            .then((res) => {
              if (res.data.status) {
                socket.send(
                  JSON.stringify({
                    event: "message_send",
                    data: {
                      conversation: id,
                      message: "",
                      attachments: [res.data.data.id],
                      sticker: null,
                      message_type: 0,
                    },
                  })
                );
              }
            })
            .catch((err) => {
              const message = JSON.parse(err.request.response).message;
              const errorMsg = JSON.parse(err.request.response).errors;
              for (let value of Object.values(errorMsg)) {
                showToast("error", value[0]);
              }
              console.log(err.request.response);
              showToast("error", message);
            });
        });
      } else if (sendWord?.length > 0) {
        Object.values(sendWord)?.map((file) => {
          data1.append("document", file);
          data1.append("doc_type", 3);
          axios
            .post(url1, data1)
            .then((res) => {
              if (res.data.status) {
                socket.send(
                  JSON.stringify({
                    event: "message_send",
                    data: {
                      conversation: id,
                      message: "",
                      attachments: [res.data.data.id],
                      sticker: null,
                      message_type: 0,
                    },
                  })
                );
              }
            })
            .catch((err) => {
              const message = JSON.parse(err.request.response).message;
              const errorMsg = JSON.parse(err.request.response).errors;
              for (let value of Object.values(errorMsg)) {
                showToast("error", value[0]);
              }
              console.log(err.request.response);
              showToast("error", message);
            });
        });
      } else if (sendExcl?.length > 0) {
        Object.values(sendExcl)?.map((file) => {
          data1.append("document", file);
          data1.append("doc_type", 3);
          axios
            .post(url1, data1)
            .then((res) => {
              if (res.data.status) {
                socket.send(
                  JSON.stringify({
                    event: "message_send",
                    data: {
                      conversation: id,
                      message: "",
                      attachments: [res.data.data.id],
                      sticker: null,
                      message_type: 0,
                    },
                  })
                );
              }
            })
            .catch((err) => {
              const message = JSON.parse(err.request.response).message;
              const errorMsg = JSON.parse(err.request.response).errors;
              for (let value of Object.values(errorMsg)) {
                showToast("error", value[0]);
              }
              console.log(err.request.response);
              showToast("error", message);
            });
        });
      } else {
        socket.send(
          JSON.stringify({
            event: "message_send",
            data: {
              conversation: id,
              message: inputStr,
              attachments: [],
              sticker: null,
              message_type: 0,
            },
          })
        );
      }
    } else {
      showToast("error", "Please Type Something!");
    }


    setOfset(0);
    // getLiveChat();
    setInputStr("");
    setSendFile("");
    setSendVedio("");
    setSendAudio("");
    setSendExcl("");
    setSendWord("");
  };
  useEffect(() => {
    socket.onmessage = (event) => {
      const triggerdEvent = event?.event;
      const lastText = JSON.parse(event.data);
      console.log("lastText", lastText?.data);
      // setLiveChat(liveChat.shift())
      // setLiveChat([...liveChat,lastText.data])
      if (triggerdEvent === "message_open") {
        console.log("event on messege", event);
        const conversation = event?.data?.conversation;
        if (conversation === activeFnd.id) {
          setLiveChat((prevState) => {
            return prevState.map((obj) => {
              return {
                ...obj,
                status: 2,
              };
            });
          });
        }
      } else if (triggerdEvent === "message_received") {
        const conversation = event?.data?.conversation;


        if (conversation === activeFnd.id) {
        }
      } else {
        setIndex(index + 1);
      }
    };
  }, [activeFnd.id]);


  useEffect(() => {
    socket.onmessage = (event) => {
      setIndex(index + 1);
      setOpen(event.data);
      // console.log("socketEvent", (event));
      const lastMessege = JSON.parse(event.data);
     
      // console.log("socketEventLive", liveChat);
      const data = [...liveChat, lastMessege];
      // console.log("socketEvent", data);
      console.log('lastMessege',lastMessege)
      if(event?.event === 'message_open'){
        if (newData[newData?.length - 1]?.sender?.id !== cUser?.id) {
          socket.onmessage({
            event: "message_open",
            data: {
              conversation: event.data.conversation,
              message: event.data.message,
            },
          });
        }
      }
     
    };
  }, []);
  // console.log("messeges", messages);
  //write a code for fetch new data


  // console.log("messegesopen", open);
  console.log("res.data.data.id live_chat", liveChat);
  console.log("chat", chat);
  const timeFunction = (time) => {
    const date = new Date(time);
    const options = { hour: "numeric", minute: "numeric", hour12: true };
    const formatter = new Intl.DateTimeFormat("en-US", options);
    const formattedTime = formatter?.format(date);
    return formattedTime;
  };
  const dataRemove = () => {
    setInputStr("");
    setSendFile("");
    setSendVedio("");
    setSendAudio("");
    setSendExcl("");
    setSendWord("");
  };
  // console.log(sendFile,'img')


  const handleSend = (e) => {
    if (
      inputStr ||
      sendAudio ||
      sendExcl ||
      sendVedio ||
      sendWord ||
      sendFile
    ) {
      if (e.keyCode == 13) {
        handleClick(inputStr, chat.id);
        dataRemove();
      }
    }
  };
  const gDate = (d) => {
    return d?.created_at?.split("T")[0];
  };


  let lastIdOneIndex = liveChat.length - 1;
  const deleteChat = (data) => {
    if (forwardData?.sender?.id !== userData?.id) {
      swal("Sorry You can not delete other's messege", {
        icon: "error",
      });
    }else{
      swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Data",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
          socket.send(
            JSON.stringify({
              event: "delete_message",
              data: {
                conversation: forwardData?.conversation,
                message: forwardData?.id
              },
            })
          );
          setLiveChat(liveChat?.filter(live => live?.id !== forwardData?.id))
        swal(" Your data has been deleted!", {
          icon: "success",
        });
        console.log("delete1", {
          sender: forwardData.id,
          user: userData?.id,
        });
      } else {
        swal(`Your data is safe!`);
      }
    });
    }
   


    console.log(forwardData, "delete");
  };
  const handleShowForword = (data) => {
    setShowForword(true);
    // setForwardData(data);
    if (data) {
      // setInputStr(forwardData.message)
      handleCloseFor();
    }
  };
  console.log(forwardData, "forwardData");
  const sendForwardData = (id) => {
    if (forwardData?.message !== '') {
      socket.send(
        JSON.stringify({
          event: "message_send",
          data: {
            conversation: id,
            message: forwardData?.message,
            attachments: [],
            sticker: null,
            message_type: 0,
          },
        })
      );
      setForwardData({});
      showToast("success", "Successfully Forwarded");
    } else if (forwardData?.attachments?.length ){
      socket.send(
        JSON.stringify({
          event: "message_send",
          data: {
            conversation: id,
            message: '',
            attachments: [forwardData?.attachments[0]?.id],
            sticker: null,
            message_type: 0,
          },
        })
      );
      setForwardData({});
      showToast("success", "Successfully Forwarded");
    }else{
      showToast('error','Please select something')
      setForwardData({});
    }
  };



  if (ndLoading) {
    <div className="w-100 h-100 d-flex justify-content-center align-items-center">
      <CircularProgress color="inherit" />
    </div>;
  }
  if (isLoading) {
    return <NoChat />;
  }

  const newChatList = chatList?.filter((chat) => chat?.id !== activeFnd.id);
  console.log(forwardData, "chatListConvo");
  return (
    <>
      <div className="chat-container">
        <div className="sidebar_hedar hedaer_shadow sidebar_header_right2">
          <div
            className="d-flex align-items-center"
            style={{ cursor: "pointer" }}
          >
            <Avatar
              onClick={() => setRender("friend-profile")}
              src={chat?.receiver?.image_url}
            />{" "}
            <div className="ms-2  left_user">
              <span className="ms-2" style={{ fontSize: "19px" }}>
                {chat?.receiver?.first_name + " " + chat?.receiver?.last_name}
              </span>

              <p className="chat_text pt-2" style={{ marginLeft: "7px" }}>
                {chat?.receiver?.is_online === true
                  ? "online"
                  : chat?.receiver?.is_online === false &&
                    chat?.receiver?.last_seen !== null
                  ? `Last Seen ${timeFunction(chat?.receiver?.last_seen)}`
                  : `Last Seen  today`}
              </p>
            </div>
          </div>
          <div className="sidebar_header_right">
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open2 ? "long-menu" : undefined}
              aria-expanded={open2 ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleClick2}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="long-menu"
              className="pop_over3"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorEl2}
              open={open2}
              onClose={handleClose2}
              PaperProps={{
                style: {
                  // maxHeight: ITEM_HEIGHT * 4.5,
                  width: "22ch",
                  marginRight: "10ch",
                  top: "100px",
                  padding: "10px ",
                },
              }}
            >
              <MenuItem
                className="privacy my-2"
                onClick={() => {
                  setRender("friend-profile");
                  handleClose2();
                }}
              >
                {" "}
                <User color="black" size={28} className="me-3" />
                View Profile
              </MenuItem>
              {/* <MenuItem
                className="privacy my-2"
                onClick={() => {
                  deleteChat()
                }}
              >
                {" "}
                <Trash color="black" size={28} className="me-3" />
                Delete Contact
              </MenuItem> */}
            </Menu>
          </div>
        </div>
        <div className="chat-body mt-2 " onScroll={handleScroll}>
          {liveChat?.length > 0 ? (
            liveChat?.map((chat, index) => (
              <>
                {gDate(liveChat[index - 1]) !== gDate(chat) && (
                  <div className="d-flex mb-3 justify-content-center">
                    <span style={{ textAlign: "center", fontSize: "12px" }}>
                      {gDate(chat)}
                    </span>
                  </div>
                )}
                <Menu
                  id="long-menu"
                  className="pop_over3"
                  MenuListProps={{
                    "aria-labelledby": "long-button",
                  }}
                  anchorEl={anchorElFor}
                  open={openFor}
                  onClose={handleCloseFor}
                  PaperProps={{
                    style: {
                      // maxHeight: ITEM_HEIGHT * 4.5,
                      width: "18ch",
                      marginRight: "10ch",
                      top: "100px",
                      padding: "8px ",
                      boxShadow: "none",
                    },
                  }}
                >
                  <MenuItem
                    className="privacy my-2"
                    onClick={() => {
                      // setForwardData(null);
                      handleShowForword(chat);
                    }}
                  >
                    Forword
                  </MenuItem>
                  {/* <MenuItem
                    className="privacy my-2"
                    onClick={() => {
                      swal("Coming Soon");
                      handleCloseFor();
                    }}
                  >
                    Edit
                  </MenuItem> */}
                  
                    <MenuItem
                      className="privacy mt-3 mb-2"
                      onClick={() => {
                        deleteChat(chat);
                        handleCloseFor();
                      }}
                    >
                      Delete
                    </MenuItem>
                  
                </Menu>

                <div
                  className={`messege ${
                    chat?.sender?.first_name + " " + chat?.sender?.last_name ===
                    userName
                      ? "my_messege2nd"
                      : "fnd_messege2nd"
                  } `}
                  style={{
                    display: `${chat?.messege?.length === 0 && "none"}`,
                    margin: "10px 0px",
                  }}
                >
                  {chat?.message?.length === 0 ? (
                    <>
                      {" "}
                      {chat?.sender?.first_name +
                        " " +
                        chat?.sender?.last_name ===
                      userName ? (
                        <>
                          <div>
                            {chat?.attachments?.length === 1 && (
                              <IconButton
                                aria-label="more"
                                id="long-button"
                                aria-controls={
                                  openFor ? "long-menu" : undefined
                                }
                                aria-expanded={openFor ? "true" : undefined}
                                aria-haspopup="true"
                                style={{
                                  position: "relative",
                                  marginRight: "8px",
                                }}
                              >
                                <MoreVertIcon
                                  style={{
                                    marginTop: `${
                                      chat?.attachments[0]?.doc_type === 0
                                        ? "150px"
                                        : chat?.attachments[0]?.doc_type === 1
                                        ? "150px"
                                        : chat?.attachments[0]?.doc_type === 2
                                        ? "50px"
                                        : "70px"
                                    }`,
                                    position: "absolute",
                                  }}
                                  onClick={(event) =>
                                    handleClickFor(event, chat)
                                  }
                                  // onClick={()=>alert(chat?.message)}
                                />
                              </IconButton>
                            )}
                          </div>
                          {chat?.attachments?.length > 0 && (
                            <>
                              <p className="image_div_last">
                                {chat?.attachments[0]?.doc_type === 0 && (
                                  <img
                                    onClick={() => {
                                      setCurrImg(chat?.attachments[0]?.doc_url);
                                      handleShow();
                                    }}
                                    style={{
                                      borderRadius: "10px",
                                      objectFit: "cover",
                                    }}
                                    width={200}
                                    height={200}
                                    src={chat?.attachments[0]?.doc_url}
                                    alt=""
                                  />
                                )}
                                {chat?.attachments[0]?.doc_type === 1 && (
                                  <video
                                    controls
                                    src={chat?.attachments[0]?.doc_url}
                                    style={{ borderRadius: "10px" }}
                                    width={200}
                                    height={200}
                                  />
                                )}
                                {chat?.attachments[0]?.doc_type === 2 && (
                                  <audio
                                    controls
                                    src={chat?.attachments[0]?.doc_url}
                                    style={{ borderRadius: "10px" }}
                                    width={150}
                                    height={50}
                                  />
                                )}
                                {chat?.attachments[0]?.doc_type === 3 && (
                                  <img
                                    onClick={() => {
                                      const fileName =
                                        chat?.attachments[0]?.doc_url
                                          ?.split("/")
                                          .pop();
                                      const aTag = document.createElement("a");
                                      aTag.href = chat?.attachments[0]?.doc_url;
                                      aTag.setAttribute("download", fileName);
                                      document.body.appendChild(aTag);
                                      aTag.click();
                                      aTag.remove();
                                    }}
                                    width={60}
                                    src={docImg}
                                    alt=""
                                  />
                                )}

                                <span className="image_span_last mt-1">
                                  {timeFunction(chat?.created_at)}{" "}
                                  {chat?.sender?.first_name +
                                    " " +
                                    chat?.sender?.last_name ===
                                    userName && (
                                    <>
                                      {chat?.status === 0 && (
                                        <Check
                                          size={17}
                                          weight="light"
                                          color="black"
                                          style={{ marginBottom: "3px" }}
                                        />
                                      )}
                                      {chat?.status === 1 && (
                                        <Checks
                                          size={17}
                                          weight="light"
                                          color="black"
                                          style={{ marginBottom: "3px" }}
                                        />
                                      )}
                                      {chat?.status === 2 && (
                                        <Checks
                                          size={17}
                                          weight="light"
                                          color="blue"
                                          style={{ marginBottom: "3px" }}
                                        />
                                      )}{" "}
                                    </>
                                  )}
                                </span>
                              </p>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <p className="image_div_last">
                            {chat?.attachments[0]?.doc_type === 0 && (
                              <img
                                onClick={() => {
                                  setCurrImg(chat?.attachments[0]?.doc_url);
                                  handleShow();
                                }}
                                style={{
                                  borderRadius: "10px",
                                  objectFit: "cover",
                                }}
                                width={200}
                                height={200}
                                src={chat?.attachments[0]?.doc_url}
                                alt=""
                              />
                            )}
                            {chat?.attachments[0]?.doc_type === 1 && (
                              <video
                                controls
                                src={chat?.attachments[0]?.doc_url}
                                style={{ borderRadius: "10px" }}
                                width={200}
                                height={200}
                              />
                            )}
                            {chat?.attachments[0]?.doc_type === 2 && (
                              <audio
                                controls
                                src={chat?.attachments[0]?.doc_url}
                                style={{ borderRadius: "10px" }}
                                width={100}
                                height={30}
                              />
                            )}
                            {chat?.attachments[0]?.doc_type === 3 && (
                              <img
                                onClick={() => {
                                  const fileName = chat?.attachments[0]?.doc_url
                                    ?.split("/")
                                    .pop();
                                  const aTag = document.createElement("a");
                                  aTag.href = chat?.attachments[0]?.doc_url;
                                  aTag.setAttribute("download", fileName);
                                  document.body.appendChild(aTag);
                                  aTag.click();
                                  aTag.remove();
                                }}
                                width={60}
                                src={docImg}
                                alt=""
                              />
                            )}

                            <span className="image_span_last mt-1">
                              {timeFunction(chat?.created_at)}
                            </span>
                          </p>
                          <div>
                            {chat?.attachments?.length === 1 && (
                              <IconButton
                                aria-label="more"
                                id="long-button"
                                aria-controls={
                                  openFor ? "long-menu" : undefined
                                }
                                aria-expanded={openFor ? "true" : undefined}
                                aria-haspopup="true"
                                style={{
                                  position: "relative",
                                  marginRight: "8px",
                                }}
                              >
                                <MoreVertIcon
                                  style={{
                                    marginTop: `${
                                      chat?.attachments[0]?.doc_type === 0
                                        ? "150px"
                                        : chat?.attachments[0]?.doc_type === 1
                                        ? "150px"
                                        : chat?.attachments[0]?.doc_type === 2
                                        ? "50px"
                                        : "70px"
                                    }`,
                                    position: "absolute",
                                  }}
                                  onClick={(event) =>
                                    handleClickFor(event, chat)
                                  }
                                  // onClick={()=>alert(chat?.message)}
                                />
                              </IconButton>
                            )}
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {chat?.sender?.first_name +
                        " " +
                        chat?.sender?.last_name ===
                        userName && (
                        <>
                          <div>
                            {chat?.attachments?.length === 0 && (
                              <IconButton
                                aria-label="more"
                                id="long-button"
                                aria-controls={
                                  openFor ? "long-menu" : undefined
                                }
                                aria-expanded={openFor ? "true" : undefined}
                                aria-haspopup="true"
                                style={{
                                  position: "relative",
                                  marginRight: "8px",
                                }}
                              >
                                <MoreVertIcon
                                  style={{
                                    marginTop: "-15px",
                                    position: "absolute",
                                  }}
                                  onClick={(event) =>
                                    handleClickFor(event, chat)
                                  }
                                  // onClick={()=>alert(chat?.message)}
                                />
                              </IconButton>
                            )}
                          </div>
                        </>
                      )}
                      <p>
                        {chat?.message}
                        <span>
                          {timeFunction(chat?.created_at)}{" "}
                          {chat?.sender?.first_name +
                            " " +
                            chat?.sender?.last_name ===
                            userName && (
                            <>
                              {chat?.status === 0 && (
                                <Check
                                  size={17}
                                  weight="light"
                                  color="black"
                                  style={{ marginBottom: "3px" }}
                                />
                              )}
                              {chat?.status === 1 && (
                                <Checks
                                  size={17}
                                  weight="light"
                                  color="black"
                                  style={{ marginBottom: "3px" }}
                                />
                              )}
                              {chat?.status === 2 && (
                                <Checks
                                  size={17}
                                  weight="light"
                                  color="blue"
                                  style={{ marginBottom: "3px" }}
                                />
                              )}{" "}
                            </>
                          )}
                        </span>
                      </p>

                      {chat?.sender?.first_name +
                        " " +
                        chat?.sender?.last_name !==
                        userName && (
                        <>
                          <div>
                            {chat?.attachments?.length === 0 && (
                              <IconButton
                                aria-label="more"
                                id="long-button"
                                aria-controls={
                                  openFor ? "long-menu" : undefined
                                }
                                aria-expanded={openFor ? "true" : undefined}
                                aria-haspopup="true"
                                style={{
                                  position: "relative",
                                  marginRight: "8px",
                                }}
                              >
                                <MoreVertIcon
                                  style={{
                                    marginTop: "-15px",
                                    position: "absolute",
                                  }}
                                  onClick={(event) =>
                                    handleClickFor(event, chat)
                                  }
                                  // onClick={()=>alert(chat?.message)}
                                />
                              </IconButton>
                            )}
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
              </>
            ))
          ) : (
            <>
              <p
                className="d-flex justify-content-center align-items-center w-100 h-100"
                style={{ color: "white" }}
              >
                No chat{" "}
              </p>
            </>
          )}

          <div ref={containerRef}></div>
        </div>

        {attach === true && (
          <div className="attach_container" ref={buttonRef}>
            <div
              className="excl"
              style={{ background: "#31A01F" }}
              // onClick={() => setAttach(false)}
            >
              <img src={excl} alt="" />
              <input
                type="file"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel/.doc,.docx,application/msword"
                onChange={(e) => {
                  setSendExcl(e.target.files);
                  setAttach(false);
                }}
                multiple
              />
            </div>

            <div
              className="vedio"
              style={{ background: "#A410C9" }}
              // onClick={() => setAttach(false)}
            >
              <img src={vedio} alt="" />
              <input
                type="file"
                accept="video/*"
                onChange={(e) => {
                  setSendVedio(e.target.files);
                  setAttach(false);
                }}
                multiple
              />
            </div>
            <div
              className="music"
              style={{ background: "#FF7F23" }}
              // onClick={() => setAttach(false)}
            >
              <img src={music} alt="" />
              <input
                type="file"
                accept="audio/*"
                onChange={(e) => {
                  setSendAudio(e.target.files);
                  setAttach(false);
                }}
                multiple
              />
            </div>
          </div>
        )}
        {/* <div>
          <img src={url ? url : img2} alt="" />
        </div> */}

        <footer className="py-3 px-3">
          <div className="d-flex">
            <IconButton
              className="emoji_btn "
              onClick={() => setShowPicker(!showPicker)}
            >
              <EmojiEmotionsOutlinedIcon />
            </IconButton>
            {showPicker && (
              <EmojiPicker
                pickerStyle={{ position: "absolute", top: "60px" }}
                onEmojiClick={onEmojiClick}
              />
            )}
            <IconButton
              className="emoji_btn ms-2"
              ref={buttonRef}
              onClick={() => setAttach(!attach)}
            >
              <AttachFileOutlinedIcon />
            </IconButton>

            <div className="input_footer">
              {sendFile ? (
                <>
                  <div className="imageDiv">Image Selected</div>
                </>
              ) : sendVedio ? (
                <>
                  <div className="imageDiv">Vedio Selected</div>
                </>
              ) : sendAudio ? (
                <>
                  <div className="imageDiv">Audio Selected</div>
                </>
              ) : sendExcl || sendWord ? (
                <>
                  <div className="imageDiv">Document Selected</div>
                </>
              ) : (
                <input
                  type="text"
                  onChange={(e) => setInputStr(e.target.value)}
                  placeholder="Type a Message"
                  onKeyDown={handleSend}
                  value={inputStr}
                />
              )}

              <div className="cam">
                {sendExcl || sendAudio || sendFile || sendVedio || sendWord ? (
                  <CancelIcon onClick={dataRemove} />
                ) : (
                  <CameraAltOutlinedIcon onClick={handleShow3} />
                )}
              </div>
            </div>
            <IconButton
              onClick={() => handleClick(inputStr, chat.id)}
              className="send_btn ms-2"
            >
              <SendOutlinedIcon />
            </IconButton>
          </div>
        </footer>
        <>
          <Modal centered show={show} onHide={handleClose}>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={currImg}
                width={400}
                // height={300}
                // style={{ objectFit: "cover", borderRadius: "5px" }}
                alt=""
              />
              <div className="my-2 download">
                <button
                  target="_blank"
                  onClick={handleDownload}
                  className="px-3 py-2 "
                  style={{
                    border: "1px solid blue",
                    outline: "none",
                    borderRadius: "5px",
                    color: "blue",
                  }}
                >
                  Download
                </button>
              </div>
            </Modal.Body>
          </Modal>
        </>
        <>
          <Modal centered show={show3} onHide={handleClose3}>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              {!cam ? (
                <>
                  <div className="cam2 " style={{ marginLeft: "150px" }}>
                    <DesktopTower size={40} color={"black"} />
                    <br /> Device
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e) => {
                        setSendFile(e.target.files);
                        handleClose3();
                      }}
                      // setSendFile(e.target.files)
                      name="images"
                      // multiple
                    />
                  </div>
                  {/* <div
                    className="cam2"
                    onClick={() => {
                      handleShow4();
                      setCam(true);
                    }}
                  >
                    <Camera size={40} color={"black"} />
                    <br /> Camera
                  </div> */}
                </>
              ) : (
                <div>
                  <Webcam
                    ref={webcamRef}
                    // audio={true}
                    screenshotFormat="image/jpeg"
                    videoConstraints={videoConstraints}
                    onUserMedia={onUserMedia}
                    mirrored
                  />
                  <div className="download my-2 d-flex justify-content-center">
                    <button
                      onClick={() => {
                        capturePhoto();
                        setCam(false);
                      }}
                      className="px-3 py-2 "
                      style={{
                        border: "1px solid blue",
                        outline: "none",
                        borderRadius: "5px",
                        color: "blue",
                      }}
                    >
                      Capture
                    </button>
                  </div>
                </div>
              )}
            </Modal.Body>
          </Modal>
        </>
        {/* forword */}
        <>
          <Modal centered show={showForword} onHide={handleCloseForword}>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div
                className=" px-4"
                style={{ maxHeight: "400px", overflowY: "scroll" }}
              >
                {/* {forwardData.id} */}
                {newChatList.length > 0 ? (
                  newChatList?.map((member) => (
                    <>
                      <div
                        key={member.id}
                        className="d-flex my-2"
                        style={{ alignItems: "center" }}
                      >
                        <img
                          src={
                            member?.receiver
                              ? member?.receiver?.image_url
                              : member?.group_image_url
                          }
                          style={{ borderRadius: "50%" }}
                          width={50}
                          alt=""
                        />
                        <p className="ms-3 me-2" style={{ minWidth: "200px" }}>
                          {member.group_name}
                        </p>

                        <div
                          onClick={() => {
                            sendForwardData(member.id);
                            // setForwardData({})
                            handleCloseForword();
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <PaperPlaneTilt color="blue" size={23} />
                        </div>
                      </div>
                    </>
                  ))
                ) : (
                  <>
                    <span className="my-3" style={{ textAlign: "center" }}>
                      No Contact Available{" "}
                    </span>
                  </>
                )}
              </div>
            </Modal.Body>
          </Modal>
        </>
      </div>
    </>
  );
};

export default Chats;
