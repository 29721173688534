import React, { useState } from "react";
import "./Profile.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import profile from "../../../../assets/logo/ProfileImg.svg";
import { IconButton, Menu, MenuItem } from "@mui/material";
import {
  Camera,
  Envelope,
  Image,
  Phone,
  TrashSimple,
  User,
  Users,
} from "phosphor-react";
import newProfile from "../../../../assets/logo/iProfile.jpg";
import demoImg from "../../../../assets/img/user-placeholder.svg";
import { useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../../../Const/Url";
import MainLoader from "../../../Authentication/MainLoader";
import { showToast } from "../../../../utils/ToastHelper";
import { Button, Modal } from "react-bootstrap";
import swal from "sweetalert";

const Profile = ({ setRender }) => {
  const [show, setShow] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [image, setImage] = useState(null);
  const handleShow = () => setShow(true);

  console.log(selectedImages);
  console.log("image", image);
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const userData = localStorage.getItem("userData");
  const profileData = JSON.parse(userData);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSelectFile = (event) => {
    const selectedFiles = event.target.files;
    const selectedFilesArray = Array.from(selectedFiles);

    const imagesArray = selectedFilesArray.map((file) => {
      return URL.createObjectURL(file);
    });

    setSelectedImages((previousImages) => previousImages.concat(imagesArray));
    setImage(event.target.files[0]);

    // FOR BUG IN CHROME
    event.target.value = "";
    handleClose();
  };
  const getProfile = () => {
    const url = `${BASE_URL}api/v1/auth/profile/`;
    axios
      .get(url)
      .then((res) => {
        console.log(res.data.data);
        setData(res.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        const errorMsg = JSON.parse(err.request.response).errors;
        for (let value of Object.values(errorMsg)) {
          showToast("error", value[0]);
        }
        console.log(err.request.response);
        showToast("error", message);
      });
  };
  useEffect(() => {
    getProfile();
  }, []);

  const postImage = () => {
    const url1 = `${BASE_URL}api/v1/auth/documents/upload/`;
    const url2 = `${BASE_URL}api/v1/auth/profile/`;
    const data1 = new FormData();
    data1.append("document", image);
    data1.append("doc_type", 0);
    const data2 = {
      first_name: data.first_name,
      last_name: data?.last_name,
      image: image,
      bio: data.bio,
    };
    axios
      .post(url1, data1)
      .then((res) => {
        console.log(res.data.data);
        if (res.data.status) {
          axios
            .post(url2, {
              first_name: data.first_name,
              last_name: data?.last_name,
              image: res.data.data.id,
              bio: data.bio,
            })
            .then((res) => {
              if (res.data.status) {
                console.log(res.data);
                showToast("success", "Data Updated Successfully.");
                getProfile();
                setSelectedImages(null);
              }
            })
            .catch((err) => {
              const message = JSON.parse(err.request.response).message;
              const errorMsg = JSON.parse(err.request.response).errors;
              for (let value of Object.values(errorMsg)) {
                showToast("error", value[0]);
              }
              
              showToast("error", message);
            });
        }
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        const errorMsg = JSON.parse(err.request.response).errors;
        for (let value of Object.values(errorMsg)) {
          showToast("error", value[0]);
        }
        console.log(err.request.response);
        showToast("error", message);
      });
  };
  const deleteProfile = () => {
    const url2 = `${BASE_URL}api/v1/auth/profile/`;
    if (data?.image === 11) {
      swal("Alert!", "Add Profile First!", "error");
    } else {
      swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover profile picture!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          axios
            .post(url2, {
              first_name: data.first_name,
              last_name: data?.last_name,
              image: 11,
              bio: data.bio,
            })
            .then((res) => {
              if (res.data.status) {
                console.log(res.data);
                swal("Poof! Your profile picture file has been deleted!", {
                  icon: "success",
                });
                getProfile();
              }
            })
            .catch((err) => {
              const message = JSON.parse(err.request.response).message;
              const errorMsg = JSON.parse(err.request.response).errors;
              for (let value of Object.values(errorMsg)) {
                showToast("error", value[0]);
              }
              console.log(err.request.response);
              showToast("error", message);
            });
        } else {
          swal("Your profile picture is safe!");
        }
      });
    }
  };

  if (isLoading) {
    return (
      <div>
        <MainLoader />
      </div>
    );
  }
  return (
    <div className="profile_main">
      <div className="profile_hedaer">
        <div className="profile_top">
          <IconButton onClick={() => setRender("home")}>
            <ArrowBackIcon />
          </IconButton>
          <h3 style={{ fontSize: "24px" }}>Profile</h3>
        </div>
      </div>
      <div className="user_profile">
        <IconButton
          style={{ background: "transparent", border: "none", outline: "none" }}
          aria-label="more"
          id="long-button"
          aria-controls={open ? "long-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <div className="user_img_div user_profile_new_div">
            <img
              className="img-fluid "
              src={
                selectedImages?.length > 0
                  ? selectedImages[selectedImages.length - 1]
                  : data?.image_url
              }
              alt=""
              style={{objectFit:'cover'}}
            />
            <div className="top_div">
              <Camera size={26} weight="light" color="white" /> <br />
              <span>CHANGE PROFILE </span> <br />
              <span style={{ marginTop: "-5px" }}>PICTURE</span>
            </div>
          </div>
        </IconButton>
        <Menu
          id="long-menu"
          className="profile_pop"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              // maxHeight: ITEM_HEIGHT * 4.5,
              width: "20ch",
              marginRight: "10ch",
              top: "160px",
              padding: "15px 8px",
            },
          }}
        >
          <MenuItem
            onClick={() => {
              handleShow();
              handleClose();
            }}
          >
            <Image size={24} weight="light" color="#1F5DA0" className="me-3" />
            View Photo
          </MenuItem>
          {/* modal View */}

          {/* modal View */}
          <MenuItem onClick={handleClose}>
            <Camera size={24} weight="light" color="#1F5DA0" className="me-3" />
            Take Photo
          </MenuItem>
          <MenuItem className="file_upload">
            <Image size={24} weight="light" color="#1F5DA0" className="me-3" />
            Upload Photo
            <input
              type="file"
              accept="image/*"
              onChange={onSelectFile}
              name="images"
            />
          </MenuItem>
          <MenuItem
            onClick={() => {
              deleteProfile();
              handleClose();
            }}
          >
            {" "}
            <TrashSimple
              color="#1F5DA0"
              size={24}
              weight="light"
              className="me-3"
            />
            Delete Photo
          </MenuItem>
          {/* <MenuItem onClick={handleClose}>option</MenuItem> */}
        </Menu>

        <Modal
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={show}
          onHide={() => setShow(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{color:'#1F5DA0'}} center id="contained-modal-title-vcenter">
              Profile
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex justify-content-center">
              <img
                width={300}
                height={400}
                style={{ objectFit: "cover", borderRadius: "10px" }}
                src={
                  selectedImages?.length > 0
                    ? selectedImages[selectedImages.length - 1]
                    : data?.image_url
                }
                alt=""
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            {/* <Button onClick={props.onHide}>Close</Button> */}
          </Modal.Footer>
        </Modal>

        <h2>
          {data.first_name} {data.last_name}
        </h2>
        <button onClick={() => setRender("edit-profile")}>Edit Profile</button>
      </div>
      <div className="user_info mt-4">
        <div className="name">
          <User size={36} weight="light" color="#1F5DA0" />
          <h5>
            {data.first_name} {data.last_name}
          </h5>
        </div>
        <hr />
        <div className="name">
          <Phone size={36} weight="light" color="#1F5DA0" />
          <h5>{data.mobile}</h5>
        </div>
        <hr />
        <div className="name">
          <Envelope size={36} weight="light" color="#1F5DA0" />
          <h5>{data.email}</h5>
        </div>
        <hr />
      </div>
      {selectedImages?.length > 0 && (
        <div className="user_profile mt-4" style={{ marginTop: "40px" }}>
          <button className="mt-3" onClick={postImage}>
            Save Image
          </button>
        </div>
      )}
    </div>
  );
};

export default Profile;
