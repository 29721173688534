import React from "react";
import nChat from "../../../assets/logo/Nochat.svg";
import "./Chatroom.css";

const NoChat = () => {
  return (
    <div className="no_chat_main">
      <div>
        <img src={nChat} className="img-fluid" alt="" />
        <h2>All You Need is Here</h2>
        <p className="mt-3">Your Secured and Fastest messaging application</p>
        <p>It is powerful, fast and free!</p>
      </div>
    </div>
  );
};

export default NoChat;
