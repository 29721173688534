import { IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { BASE_URL } from "../../../Const/Url";
import axios from "axios";
import { Envelope, Phone, User } from "phosphor-react";
import { Modal } from "react-bootstrap";
import { showToast } from "../../../../utils/ToastHelper";

const FriendProfile = ({ setRender, activeFnd ,setupdatePp}) => {
  const [admin, setAdmin] = useState(false);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const [isLoading, setIsLoading] = useState(true);
  const [friend, setFriend] = useState({});
  const cUser = JSON.parse(localStorage.getItem("userData"));
  const [uploaded, setUploaded] = useState(null);
  const onSelectFile = (event) => {
    setUploaded(event.target.files[0]);
  };

  const getSingleChat = () => {
    const url = `${BASE_URL}api/v1/chat/mobile/conversation/${activeFnd.id}/`;
    axios
      .get(url)
      .then((res) => {
        console.log("friend", res.data.data);
        setFriend(res.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        console.log(message);
      });
  };
  const getGroupAdmin = () => {
    const url = `${BASE_URL}api/v1/chat/mobile/conversation/${activeFnd.id}/group_admins/`;
    axios
      .get(url)
      .then((res) => {
        console.log("admin", res?.data?.data?.results);
        setAdmin(res?.data?.data?.results);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        console.log(message);
      });
  };

  useEffect(() => {
    getSingleChat();
  }, [activeFnd]);
  useEffect(()=>{
    // getGroupAdmin()
  },[])
  const updateGroupImage = ()=>{
    const url1 = `${BASE_URL}api/v1/auth/documents/upload/`;
    const url2 = `${BASE_URL}api/v1/chat/mobile/conversation/${activeFnd.id}/`;
    const data1 = new FormData();
    data1.append("document", uploaded);
    data1.append("doc_type", 0);
    axios
    .post(url1, data1)
    .then((res) => {
      console.log(res.data.data);
      if (res.data.status) {
        axios
          .patch(url2, {
            group_image:res.data.data.id
          })
          .then((res) => {
            if (res.data.status) {
              console.log(res.data);
              showToast("success", "Data Updated Successfully.");
              getSingleChat();
              setUploaded(null);
              setupdatePp(prev => !prev)
            }
          })
          .catch((err) => {
            const message = JSON.parse(err.request.response).message;
            const errorMsg = JSON.parse(err.request.response).errors;
            for (let value of Object.values(errorMsg)) {
              showToast("error", value[0]);
            }

            showToast("error", message);
          });
      }
    })
    .catch((err) => {
      const message = JSON.parse(err.request.response).message;
      const errorMsg = JSON.parse(err.request.response).errors;
      for (let value of Object.values(errorMsg)) {
        showToast("error", value[0]);
      }
      console.log(err.request.response);
      showToast("error", message);
    })

  }
  return (
    <div className="profile_main">
      <div className="profile_hedaer">
        <div className="profile_top">
          <IconButton onClick={() => setRender("home")}>
            <ArrowBackIcon />
          </IconButton>
          <h3 style={{ fontSize: "24px" }}>Contact View</h3>
        </div>
      </div>
      <div className="user_profile3 ">
        <div
          className="user_img_div user_profile_new_div"
          style={{ cursor: "pointer" ,position:'relative'}}
          // onClick={handleShow}
        >
          <img
            className="img-fluid "
            src={
              uploaded ? URL.createObjectURL(uploaded) :
               friend?.group_image_url
            }
            alt=""
          />
          <input style={{position:'absolute',top:'0',left:'0',width:'100%',height:'100%',opacity:'0',zIndex:'2222',cursor:'pointer'}} onChange={onSelectFile} type="file" accept="image/*" />
        </div>
      </div>

      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={() => setShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title style={{color:'#1F5DA0'}} center id="contained-modal-title-vcenter">
           Contact Profile
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center">
            <img
              width={300}
              height={400}
              style={{ objectFit: "cover", borderRadius: "10px" }}
              src={
                friend?.convo_type === 0
                  ? friend?.receiver.image_url
                  : friend?.group_image_url
              }
              alt=""
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button onClick={props.onHide}>Close</Button> */}
        </Modal.Footer>
      </Modal>
      <div className="user_info mt-4">
        <div className="name">
          <User size={36} weight="light" color="#1F5DA0" />
          <h5>
            {friend?.convo_type === 0
              ? friend?.receiver.first_name + " " + friend?.receiver.last_name
              : friend?.group_name}
          </h5>
        </div>
        {friend?.convo_type === 0 && (
          <>
            <hr />
            <div className="name">
              <Phone size={36} weight="light" color="#1F5DA0" />
              <h5>{friend?.receiver.mobile}</h5>
            </div>
          </>
        )}
        <hr />
        {/* {friend?.convo_type === 0 && (
          <>
            <hr />
            <div className="name">
              <Envelope size={36} weight="light" color="#1F5DA0" />
              <h5>{friend?.receiver.email}</h5>
            </div>
            <hr />
          </>
        )} */}
        {
          uploaded && <div className="mx-3 d-flex justify-content-center">
          <button className="btn btn-danger" onClick={()=>setUploaded(null)}>Cancel</button>
          <button className="btn btn-primary ms-2" onClick={updateGroupImage}>Update</button>
        </div>
        }
        
      </div>
    </div>
  );
};

export default FriendProfile;
