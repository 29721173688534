import { IconButton } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import BeatLoader from 'react-spinners/BeatLoader';
import { BASE_URL } from '../../../Const/Url';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const PrivacyPolicy = ({ setRender }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState({});
    const getTnc = () => {
      const url = `${BASE_URL}api/v1/utility/mobile/page/0/fixed-page/`;
      axios
        .get(url)
        .then((res) => {
          console.log("T&C", res.data.data);
          setData(res.data.data);
          setIsLoading(false);
        })
        .catch((err) => {
          const message = JSON.parse(err.request.response).message;
          console.log(message);
        });
    };
    useEffect(() => {
      getTnc();
    }, []);
  return (
    <div className="profile_main">
      <div className="profile_hedaer">
        <div className="profile_top">
          <IconButton onClick={() => setRender("home")}>
            <ArrowBackIcon />
          </IconButton>
          <h3 style={{ fontSize: "22px" }}>Privacy Policy</h3>
        </div>
      </div>
      {data ? (
        <div className="settings_details px-1 px-md-2  px-lg-3">
          <hr style={{ color: "white" }} />
          <h4 style={{color:'#1F5DA0'}}>Description</h4>
          <p className="mt-4 pe-3" style={{textAlign:'justify',color:'#1F5DA0'}}>{data?.desc}</p>
          {/* <hr /> */}
        </div> 
      ): <BeatLoader color='white'/>}
    </div>
  )
}

export default PrivacyPolicy