import React, { useEffect } from "react"; //Needed for nodejs v.16
import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
  useLocation,
} from "react-router-dom";
import Login from "./modules/Authentication/Login";
import CreateAccount from "./modules/Authentication/CreateAccount";
import Verify from "./modules/Authentication/Verify";
import ChatMainContainer from "./modules/ChatComponents/ChatMainContainer";
import { ToastContainer } from "react-toastify";
import { BASE_URL } from "./modules/Const/Url";
import axios from "axios";
import swal from "sweetalert";
import ForgetPass from "./modules/Authentication/ForgetPass";
import ForgetOtp from "./modules/Authentication/ForgetOtp";


function App() {

  const history = useHistory();
  const location = useLocation();
  const current_location = location.pathname;
  const check_token = () => {
    const url = `${BASE_URL}api/v1/auth/profile/`;
    axios
      .get(url)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        console.log(message);
        if (message === "Authentication credentials were not provided." || 'Invalid token.') {
          localStorage.removeItem("is_logged_in");
          localStorage.removeItem("access_token");
          localStorage.removeItem("userData");
          swal("Login expired!", "Please login again..", "warning");
          history.push("/login");
        }
      });
  };
  useEffect(() => {
    if (current_location.includes("login") && localStorage.getItem('access_token')) {
      console.log("include");
      window.location.reload()
    } else {
      check_token();
    }
  }, [current_location]);
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <ChatMainContainer />
        </Route>
        <Route exact path="/login">
          <Login />
        </Route>
        <Route exact path="/forget_pass">
          <ForgetPass/>
        </Route>
        <Route exact path="/forget_otp">
          <ForgetOtp/>
        </Route>
        <Route exact path="/create-account">
          <CreateAccount />
        </Route>
        <Route exact path="/verify">
          <Verify />
        </Route>
      </Switch>
      <ToastContainer />
    </Router>
  );
}

export default App;
