import React, { useEffect, useState } from "react";
import "./SideBar.css";
import Avatar from "@mui/material/Avatar";
import avaterImg from "../../../assets/logo/avater.svg";
import MessageIcon from "@mui/icons-material/Message";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton, Menu, MenuItem } from "@material-ui/core";
import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";
import active from "../../../assets/logo/active.svg";
import active2 from "../../../assets/logo/active2.svg";
import active3 from "../../../assets/logo/activeFe.svg";
import { Check, Checks, GearSix, Image, SignOut, User, Users } from "phosphor-react";
import PrivacyTipOutlinedIcon from "@mui/icons-material/PrivacyTipOutlined";
import CastConnectedOutlinedIcon from "@mui/icons-material/CastConnectedOutlined";
import InventoryOutlinedIcon from "@mui/icons-material/InventoryOutlined";
import Faq from "../../Svgs/Faq";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import PermContactCalendarOutlinedIcon from "@mui/icons-material/PermContactCalendarOutlined";
import { showToast } from "../../../utils/ToastHelper";
import { useHistory } from "react-router-dom";
import { BASE_URL } from "../../Const/Url";
import axios from "axios";
import MainLoader from "../../Authentication/MainLoader";
import BeatLoader from "react-spinners/BeatLoader";

const SideBar = ({
  setRender,
  chatList,
  setActiveFnd,
  activeFnd,
  chatLoading,
  getMoreData,
  socket,
  render,
  lastStatus
}) => {
  const [clickedItems, setClickedItems] = useState([]);
  const [previousItem, setPreviousItem] = useState(0);
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [query, setQuery] = useState("");

  function handleClickId(item) {
    const newClickedItems = [...clickedItems, item];
    if (newClickedItems?.length >= 2) {
      setClickedItems(newClickedItems.slice(-2));
    } else {
      setClickedItems(newClickedItems);
    }

    localStorage.setItem(
      "clickedItems",
      JSON.stringify(newClickedItems.slice(-2))
    );
    localStorage.setItem(
      "previousItem",
      JSON.stringify(previousItem === undefined ? 0 : previousItem)
    );
  }
  useEffect(() => {
    setPreviousItem(clickedItems[clickedItems?.length - 1]);
  }, [clickedItems]);

  useEffect(() => {
    console.log("soketid", socket?.id);
  }, [socket]);

  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorEl2);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };
  const logout_func = () => {
    localStorage.removeItem("is_logged_in");
    localStorage.removeItem("access_token");
    localStorage.removeItem("userData");
    showToast("success", "Logged Out Successfully");

    history.push("/login");
  };
  const options = ["None", "Atria", "Callisto"];
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState({});
  // const userData = localStorage.getItem('userData')
  // const data = JSON.parse(userData)
  const name = userData?.first_name + " " + userData?.last_name;
  const img = userData?.image_url;
  const userFirstName = userData?.first_name;
  useEffect(() => {
    const url = `${BASE_URL}api/v1/auth/profile/`;
    axios
      .get(url)
      .then((res) => {
        console.log(res);
        setUserData(res.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        console.log(message);
      });
  }, []);
  // get chats

  useEffect(() => {
    if (!localStorage.getItem("is_logged_in")) {
      history.push("/login");
    }
  }, []);
  window.onscroll = function () {
    if (
      window.innerHeight + document.documentElement.scrollTop ===
      document.documentElement.offsetHeight
    ) {
      getMoreData();
    }
  };
  const keys = ['group_name'];
  // const getData = chatList
  const getData = chatList?.filter((p) =>
    keys.some((key) => p[key]?.toString()?.toLowerCase()?.includes(query))
  );
  console.log("filterData", getData);
  // console.log("chatList", chatList);
  const timeFunction = (time) => {
    const date = new Date(time);
    const options = { hour: "numeric", minute: "numeric", hour12: true };
    const formatter = new Intl.DateTimeFormat("en-US", options);
    const formattedTime = formatter.format(date);
    return formattedTime;
  };
  
  const idChange = async (chat) => {
    setActiveFnd({
      id: chat?.id,
      type: chat?.convo_type,
    });
    await handleClickId(chat.id);
  };
  


  if (isLoading && chatLoading) {
    return (
      <div className="loader_main ">
        <div className="d-flex flex-column justify-content-center align-items-center">
          <BeatLoader color="#fff" />
        </div>
      </div>
    );
  }
  console.log('lastStatus161',lastStatus)
  return (
    <>
      <div className="sidebar_hedar">
        <div
          className="d-flex align-items-center"
          style={{ cursor: "pointer" }}
        >
          <Avatar
            onClick={() => setRender("profile")}
            src={img ? img : avaterImg}
          />{" "}
          <span className="ms-2" style={{ fontSize: "17px" }}>
            {name}
          </span>
        </div>
        <div className="sidebar_header_right">
          <IconButton onClick={() => {
              setRender("contact");
              
            }}>
            <MessageIcon />
          </IconButton>
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open2 ? "long-menu" : undefined}
            aria-expanded={open2 ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleClick2}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="long-menu"
            className="pop_over2"
            MenuListProps={{
              "aria-labelledby": "long-button",
            }}
            anchorEl={anchorEl2}
            open={open2}
            onClose={handleClose2}
            PaperProps={{
              style: {
                // maxHeight: ITEM_HEIGHT * 4.5,
                width: "27ch",
                marginRight: "10ch",
                top: "200px",
                padding: "15px 8px",
              },
            }}
          >
            <MenuItem
              className="privacy my-2"
              onClick={() => {
                setRender("create-group");
                handleClose();
              }}
            >
              {" "}
              <Users
                color="#1F5DA0"
                size={28}
                weight="light"
                className="me-3"
              />
              New Group
            </MenuItem>
            
            <MenuItem
              className="privacy my-2"
              onClick={() => {
                setRender("settings");
                handleClose2();
              }}
            >
              {" "}
              <GearSix
                color="#1F5DA0"
                size={28}
                className="me-3"
              />
              Settings
            </MenuItem>
            <MenuItem
              className="privacy my-2"
              onClick={() => {
                setRender("privacy-policy");
                handleClose2();
              }}
            >
              {" "}
              <PrivacyTipOutlinedIcon
                color="#1F5DA0"
                size={28}
                className="me-3"
              />
              Privacy Policy
            </MenuItem>
            {/* <MenuItem onClick={handleClose2}>
              {" "}
              <Faq />
              FAQ
            </MenuItem> */}

            <MenuItem className="privacy my-2" onClick={() => {
                setRender("T&C");
                handleClose2();
              }}>
              {" "}
              <InventoryOutlinedIcon
                color="#1F5DA0"
                size={28}
                weight="light"
                className="me-3"
              />
              Terms and Conditions
            </MenuItem>
            <MenuItem className="privacy my-2" onClick={() => {
                setRender("about-us");
                handleClose2();
              }}>
              {" "}
              <PermContactCalendarOutlinedIcon className="me-3" />
              About Us
            </MenuItem>
            <MenuItem
              className="privacy my-2"
              onClick={() => {
                setRender("contact-us");
                handleClose2();
              }}
            >
              {" "}
              <ContactsOutlinedIcon
                color="#1F5DA0"
                size={28}
                weight="light"
                className="me-3"
              />
              Contact Us
            </MenuItem>
            <MenuItem
              className="privacy my-2"
              onClick={() => {
                logout_func();
                handleClose2();
              }}
            >
              {" "}
              <SignOut
                color="#1F5DA0"
                size={28}
                weight="light"
                className="me-3"
              />
              Log Out
            </MenuItem>
          </Menu>
        </div>
      </div>
      <div
        className="sidebar_search "
        style={{ background: "white", padding: " 14px" }}
      >
        <div className="search">
          <SearchIcon />
          <input
            type="text"
            placeholder="Search or New Chat"
            value={query}
            onChange={(e) => setQuery(e.target.value.toLocaleLowerCase())}
          />
        </div>
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? "long-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <FilterListIcon />
        </IconButton>
        <Menu
          id="long-menu"
          className="pop_over"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              // maxHeight: ITEM_HEIGHT * 4.5,
              width: "20ch",
              marginRight: "10ch",
              top: "200px",
              padding: "15px 8px",
            },
          }}
        >
          <MenuItem
            onClick={() => {
              setRender("contact");
              handleClose();
            }}
          >
            <User size={26} weight="light" className="me-3" />
            Contact
          </MenuItem>
          <MenuItem
            onClick={() => {
              setRender("create-group");
              handleClose();
            }}
          >
            {" "}
            <Users color="#1F5DA0" size={28} weight="light" className="me-3" />
            New Group
          </MenuItem>
          {/* <MenuItem onClick={handleClose}>option</MenuItem> */}
        </Menu>
      </div>
      <div
        className="sidebar_chats"
        style={{ borderTop: "1px solid #1D1D1D4D" }}
      >
        {getData ? (
          getData?.map((chat) => (
            // is_chat_active
            <div
              key={chat?.id}
              onClick={() => idChange(chat)}
              className={`single_chat ${
                chat?.id === activeFnd?.id && "is_chat_active"
              }`}
              style={{ padding: "10px 16px" }}
            >
              <div className="d-flex align-items-center">
                <div className="chat_image">
                  <img
                    width={55}
                    height={55}
                    style={{ borderRadius: "50%" }}
                    src={
                      chat?.convo_type === 0
                        ? chat?.receiver?.image_url
                        : chat?.group_image_url
                    }
                    alt=""
                  />

                  {chat?.receiver?.is_online === true && <div></div>}
                  {/* {lastStatus.find(stat => stat?.id === chat?.id)?.status === true && <div></div>} */}
                  {/* {
                    chat?.id !== activeFnd?.id && chat?.receiver?.is_online === true && <div></div>
                  } */}
                </div>
                <div className="ms-3 d-flex flex-column justify-content-center ">
                  <h5 className="chat_name">
                    {chat?.convo_type === 0
                      ? chat?.receiver?.first_name +
                        " " +
                        chat?.receiver?.last_name
                      : chat?.group_name}
                  </h5>

                  <span
                    className={`chat_text ${
                      chat?.last_message?.status === 0 || chat?.last_message?.status === 1? "black" : '#A7A7A7'
                    }`}
                  >
                    {userFirstName ===
                      chat?.last_message?.sender?.first_name && (
                      <span style={{ color: "gray" }}>You : </span>
                    )}
                    {chat?.last_message?.attachments.length > 0 && (
                      <>
                        <Image size={16} weight="light" color="#A7A7A7" />
                        {chat?.last_message?.attachments[0].doc_type === 0 &&
                          "  Photo"}
                        {chat?.last_message?.attachments[0].doc_type === 1 &&
                          "  Video"}
                        {chat?.last_message?.attachments[0].doc_type === 2 &&
                          "Audio"}
                        {chat?.last_message?.attachments[0].doc_type === 3 &&
                          "  Attachment"}
                      </>
                    )}
                    {name ===
                      chat?.last_message?.sender?.first_name +
                        " " +
                        chat?.last_message?.sender?.last_name && ""}

                    {chat?.last_message &&
                    chat?.last_message?.message?.length > 15
                      ? `${chat?.last_message?.message?.slice(0, 15)}...`
                      : chat?.last_message &&
                        chat?.last_message?.message?.length < 15
                      ? chat?.last_message?.message
                      : "Say Hi !!"}

                    {/* {chat?.last_message
                      ? chat?.last_message?.message
                      : "Say Hi !!"} */}
                  </span>
                </div>
              </div>
              <div className="d-flex flex-column align-items-center justify-content-between">
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{
                    borderRadius: "50%",
                    width: "18px",
                    height: "18px",
                    background: "#0076F6",
                    opacity: `${chat?.unseen_count === 0 ? 0 : 1}`,
                  }}
                >
                  <span style={{ fontSize: "10px", color: "white" }}>
                    {"0"}
                  </span>
                </div>
                <div>
                  <span style={{ fontSize: "12px", color: "#000" }}>
                    {userFirstName ===
                      chat?.last_message?.sender?.first_name && (
                      <>
                        {chat?.last_message?.status === 0 && (
                          <Check size={15} weight="light" color="#000" />
                        )}
                        {chat?.last_message?.status === 1 && (
                          <Check size={15} weight="light" color="#000" />
                        )}
                        {chat?.last_message?.status === 2 && (
                          <Checks size={15} weight="light" color="blue" />
                        )} 
                        {/* {
                          lastStatus?.find(stat => stat?.id === chat?.id)?.status === 0 && (
                            <Check size={15} weight="light" color="#000" />)
                        }
                        {
                          lastStatus?.find(stat => stat?.id === chat?.id)?.status === 1 && (
                            <Checks size={15} weight="light" color="#000" />)
                        }
                        {
                          lastStatus?.find(stat => stat?.id === chat?.id)?.status === 2 && (
                            <Checks size={15} weight="light" color="blue" />)
                        } */}
                      </>
                    )}
                    {' '}
                    {chat?.last_message?.created_at
                      ? timeFunction(chat?.last_message?.created_at)
                      : ""}
                  </span>
                </div>
              </div>
            </div>
          ))
        ) : (
          <>
            <p className="text-center">No Data</p>
          </>
        )}
      </div>
    </>
  );
};

export default SideBar;
